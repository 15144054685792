<template>
  <div>
    <v-card v-if="$can('user_list', 'user')">
      <v-form
        ref="searchForm"
        @submit.prevent="toSearch()"
      >
        <v-card-text class="d-flex flex-row-reverse">
          <div class="d-flex align-center pt-3">
            <v-btn
              class="me-3"
              @click="resetSearch()"
            >
              <span>重置</span>
            </v-btn>
          </div>
          <div class="d-flex align-center pt-3">
            <v-btn
              color="secondary"
              class="me-3"
              type="submit"
            >
              <v-icon
                size="18"
                class="me-1"
              >
                {{ icons.mdiMagnify }}
              </v-icon>
              <span>查询</span>
            </v-btn>
          </div>

          <div class="d-flex align-center pt-3 mr-10">
            <label for="searchName"> <span class="text-subtitle-2">关键字搜索：</span> </label>
            <v-text-field v-model="search.name"></v-text-field>
          </div>
          <div class="d-flex align-center pt-3 mr-10">
            <label for="levelId"> <span class="text-subtitle-2">会员等级：</span> </label>
            <v-select
              id="levelId"
              v-model="search.levelId"
              item-text="levelName"
              item-value="id"
              :items="memberLevels"
            ></v-select>
          </div>
        </v-card-text>
      </v-form>
    </v-card>

    <v-card
      v-if="$can('user_list', 'user')"
      class="mt-2"
    >
      <v-card-title
        v-if="$can('user_save', 'user')"
        class="d-flex align-center flex-wrap pb-0"
      >
        <div class="d-flex align-center pb-5">
          <!-- create invoice -->
          <v-btn
            color="primary"
            class="me-3"
            @click="editItem()"
          >
            <v-icon
              size="18"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>新增</span>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :footer-props="{
            itemsPerPageAllText: '全部',
            itemsPerPageText: '每页显示 ',
            showCurrentPage: true
          }"
          :page="pages.page"
          :items-per-page="pages.itemsPerPage"
          :server-items-length="pages.serverItemsLength"
          :headers="getHeader()"
          :items="users"
          no-data-text="无数据"
          @pagination="loadPage"
        >
          <template #[`item.nickName`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span>{{ item.nickName }}&nbsp;<v-icon
                  v-if="item.isWeixin"
                  small
                  class="me-2"
                  color="success"
                >
                  {{ icons.mdiWechat }}
                </v-icon></span>
              </div>
            </div>
          </template>

          <template #[`item.email`]="{ item }">
            <span v-if="item.email"> {{ item.email }}</span>
            <span v-else><v-chip
              small
              class="font-weight-semibold text-capitalize"
            >
              未绑定
            </v-chip></span>
          </template>
          <template #[`item.accountNonLocked`]="{ item }">
            <div
              style="margin-left: 11px"
              @click.stop="lockUser(item)"
            >
              <h3 v-if="item.accountNonLocked">
                <a
                  href="javascript:void(0)"
                ><v-icon
                  class="me-2"
                >
                  {{ icons.mdiLockOpen }}
                </v-icon></a>
              </h3>
              <h3 v-else>
                <a
                  href="javascript:void(0)"
                ><v-icon
                  class="me-2"
                >
                  {{ icons.mdiLock }}
                </v-icon></a>
              </h3>
            </div>
          </template>

          <template #[`item.actions`]="{ item }">
            <v-tooltip
              v-if="$can('user_edit', 'user')"
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  class="me-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="editItem(item)"
                >
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </template>
              <span>编辑</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>

      <v-navigation-drawer
        v-model="editDialog"
        temporary
        touchless
        :right="!$vuetify.rtl"
        :width="$vuetify.breakpoint.smAndUp ? 750 : '100%'"
        app
      >
        <v-card height="100%">
          <div class="drawer-header d-flex align-center">
            <span class="font-weight-semibold text-base text-h6">{{ editedItem.id ? '编辑' : '添加' }}用户</span>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-5"
              color="primary"
              :disabled="commitFlag"
              :loading="commitFlag"
              @click="handleFormSubmit()"
            >
              保存
            </v-btn>
            <v-btn
              icon
              small
              @click="editDialog = false"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </div>

          <v-card-text
            style="background-color: #F4F5FA; height: 90%; padding-top: 20px;"
            class="overflow-auto"
          >
            <v-card>
              <v-form
                ref="editForm"
                lazy-validation
                @submit.prevent="handleFormSubmit"
              >
                <v-card-text class="pa-3">
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <label for="name"><span style="color: red">*</span> 用户昵称：</label>
                      </v-col>
                      <v-col
                        cols="12"
                        md="9"
                      >
                        <v-text-field
                          id="name"
                          v-model="editedItem.nickName"
                          :rules="rules.required"
                          required
                          label="用户昵称"
                          outlined
                          clearable
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col

                        cols="12"
                        md="2"
                      >
                        <label for="phone"><span style="color: red">*</span> 手机号码：</label>
                      </v-col>
                      <v-col
                        cols="12"
                        md="9"
                      >
                        <v-text-field
                          id="phone"
                          v-model="editedItem.phone"
                          :rules="rules.phone"
                          required
                          label="手机号码"
                          outlined
                          clearable
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <label for="roleId"><span style="color: red">*</span> 分配角色：</label>
                      </v-col>
                      <v-col
                        cols="12"
                        md="9"
                      >
                        <v-select
                          v-model="currentRoleId"
                          :items="roles"
                          label="角色类型"
                          item-text="name"
                          item-value="id"
                          dense
                          outlined
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row v-if="currentRoleId === -1 && $can('user_edit_member_list', 'user')">
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <label for="roleId"><span style="color: red">*</span> 分配会员：</label>
                      </v-col>
                      <v-col
                        cols="12"
                        md="9"
                      >
                        <v-select
                          v-model="currentLevelId"
                          :items="memberLevels"
                          item-text="levelName"
                          item-value="id"
                          label="会员等级"
                          dense
                          outlined
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row v-if="currentRoleId === -1 && $can('home_agent', 'user')">
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <label for="selectAgentId">代理归属：</label>
                      </v-col>
                      <v-col
                        cols="12"
                        md="9"
                      >
                        <v-select
                          v-model="selectAgentId"
                          :items="agents"
                          item-text="nickName"
                          item-value="id"
                          label="代理归属"
                          dense
                          outlined
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row v-if="isUpdate && currentRoleId != -1">
                      <v-col
                        v-if="$can('user_password_modify', 'user')"
                        cols="12"
                        md="2"
                      >
                        <label for="password"><span style="color: red">*</span>用户密码</label>
                      </v-col>
                      <v-col
                        v-if="$can('user_password_modify', 'user')"
                        cols="12"
                        md="9"
                      >
                        <v-btn
                          outlined
                          color="primary"
                          @click="openUpdatePasswordDialog()"
                        >
                          修改密码
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row v-else-if="currentRoleId != -1">
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <label for="password"><span style="color: red">*</span>用户密码</label>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          id="password"
                          v-model="editedItem.password"
                          required
                          :rules="rules.password"
                          label="请输入密码"
                          outlined
                          clearable
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                      >
                        <v-btn
                          outlined
                          color="info"
                          @click="randomPassDialog = true"
                        >
                          生成随机密码
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row v-if="!isUpdate && currentRoleId != -1">
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <label for="repassword"><span style="color: red">*</span>确认密码</label>
                      </v-col>
                      <v-col
                        cols="12"
                        md="9"
                      >
                        <v-text-field
                          id="repassword"
                          v-model="editedItem.repassword"
                          required
                          :rules="rules.repass"
                          label="请再次输入密码"
                          outlined
                          clearable
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-form>
            </v-card>
          </v-card-text>
        </v-card>
        <v-dialog
          v-model="updatePasswordDialog"
          width="500"
        >
          <v-card>
            <v-card-title>修改密码</v-card-title>
            <v-card-text>
              <v-form ref="passwordForm">
                <v-row style="height: 60px">
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <label for="password">密码</label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="5"
                  >
                    <v-text-field
                      id="password"
                      v-model="editedItem.password"
                      :rules="rules.password"
                      required
                      type="password"
                      label="请输入密码"
                      outlined
                      clearable
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-btn
                      outlined
                      color="info"
                      @click="openRandomPassDialog()"
                    >
                      生成随机密码
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <label for="repassword">确认密码</label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="5"
                  >
                    <v-text-field
                      id="repassword"
                      v-model="editedItem.repassword"
                      required
                      type="password"
                      :rules="rules.repass"
                      label="请再次输入密码"
                      outlined
                      clearable
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="normal"
                text
                @click="updatePasswordDialog = false"
              >
                取消
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="modifyPassword()"
              >
                确认修改
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="randomPassDialog"
          width="400"
        >
          <v-card>
            <v-card-title>生成随机密码</v-card-title>
            <v-card-text>
              <v-form ref="randomPassForm">
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <label for="randomPass">随机密码</label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="9"
                  >
                    <v-text-field
                      id="randomPass"
                      v-model="randomPass"
                      required
                      :rules="rules.required"
                      outlined
                      clearable
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="normal"
                text
                @click="mkdirRandomPass(8)"
              >
                生成
              </v-btn>
              <v-btn
                color="info"
                text
                @click="useRandomPass()"
              >
                应用
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-navigation-drawer>

      <v-dialog
        v-model="deleteDialog"
        width="300"
      >
        <v-card>
          <v-card-text> 是否删除 {{ this.deleteData.nickName }} ? </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="deleteItem()"
            >
              确认删除
            </v-btn>
            <v-btn
              color="normal"
              text
              @click="deleteDialog = false"
            >
              取消
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- <v-dialog
        v-model="allocateRoleDialog"
        width="800"
      >
        <v-card>
          <v-card-title>分配角色</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container row>
              <v-row dense>
                <v-col
                  v-for="item in roleRltData.roleList"
                  :key="item.id"
                  cols="12"
                  md="3"
                >
                  <v-checkbox
                    v-model="item.hasPermission"
                    dense
                    :label="item.name"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              text
              color="secondary"
              @click="allocateRoleDialog = false"
            >
              关闭
            </v-btn>
            <v-btn
              text
              color="error"
              @click="actionAllocateRole(false)"
            >
              解绑
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="actionAllocateRole(true)"
            >
              保存
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    </v-card>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiMagnify,
  mdiAlphaRBox,
  mdiLock,
  mdiLockOpen,
  mdiAccountTie,
  mdiWechat,
  mdiClose,
} from '@mdi/js'
import RoleApi from '@/api/system/role'
import UserApi from '@/api/ucnter/user'
import memberLevelApi from '@/api/ucnter/memberLevel'

export default {
  name: 'User',
  components: {},
  data() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMagnify,
        mdiAlphaRBox,
        mdiLock,
        mdiLockOpen,
        mdiAccountTie,
        mdiWechat,
        mdiClose,
      },
      pages: {
        page: 1,
        itemsPerPage: 15,
        serverItemsLength: 0,
      },
      rules: {
        phone: [v => !!v || '该字段不能为空', v => /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(v) || '不符合手机号码格式'],
        required: [v => !!v || '该字段不能为空'],
        password: [v => !!v || '密码不能为空', v => (v && v.length >= 8) || '密码长度不能少于8位'],
        repass: [v => !!v || '密码不能为空', v => this.editedItem.password === v || '两次密码输入不一致'],
      },
      users: [],
      editDialog: false,
      deleteDialog: false,
      updatePasswordDialog: false,
      randomPassDialog: false,
      allocateRoleDialog: false,
      loadings: {
        submitLoading: false,
      },
      isUpdate: false,
      deleteData: {},
      editedItem: {},
      defaultItem: {
        nickName: '',
        password: '',
        repassword: '',
      },
      randomPass: '',
      charset: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$',
      search: {},
      roleRltData: {
        roleList: [],
      },
      memberLevels: [],
      roles: [{
        id: -1,
        name: '商城会员',
      }],
      currentRoleId: -1,
      currentLevelId: undefined,
      commitFlag: false,
      selectAgentId: undefined,
      agents: [],
    }
  },
  computed: {
  },
  mounted() {
    if (this.$can('user_edit_member_list', 'user')) {
      memberLevelApi.listAll().then(res => {
        const { data } = res.data
        this.memberLevels = data
      })
    }
    if (this.$can('user_edit_role_list', 'user')) {
      RoleApi.list().then(res => {
        const { data } = res.data
        data.forEach(role => {
          this.roles.push(role)
        })
      })
    }
  },
  methods: {
    getHeader() {
      const baseHeader = [
        // { text: '编号', sortable: false, value: 'id' },
        { text: '用户昵称', value: 'nickName' },
        { text: '手机号', value: 'phone' },
        { text: '锁定账号', value: 'accountNonLocked' },
        { text: '添加时间', value: 'createTime' },
      ]
      if (this.$can('user_edit', 'user')

          // || this.$can('user_role_allocate', 'user')
          || this.$can('user_delete', 'user')) {
        baseHeader.push({ text: '操作', value: 'actions' })
      }

      return baseHeader
    },
    editItem(item) {
      this.selectAgentId = undefined
      if (this.$can('home_agent', 'user')) {
        UserApi.getUserByAgent().then(res => {
          this.agents = res.data.data
        })
      }
      if (item) {
        this.isUpdate = true
        if (this.$can('user_edit_get_role', 'user')) {
          RoleApi.getByUserId(item.id).then(res => {
            const { data } = res.data
            if (data.length > 0) {
              this.currentRoleId = data[0].id
            } else {
              this.currentRoleId = -1
            }
          })
        }
        if (this.$can('get_user_agent', 'user')) {
          UserApi.getAgentByUserId(item.id).then(res => {
            this.selectAgentId = res.data.data
          })
        }
        this.currentLevelId = item.levelId
        this.editedItem = item
      } else {
        this.initEditedItem()
        this.randomPass = ''
        this.currentRoleId = -1
        this.currentLevelId = undefined
        this.$nextTick(() => {
          const { editForm } = this.$refs

          editForm.resetValidation()
        })
        this.isUpdate = false
      }
      this.commitFlag = false
      this.editDialog = true
    },
    deleteItem() {
      UserApi.delete(this.deleteData.id).then(res => {
        this.$toast.success('删除成功！')
        this.deleteDialog = false
        this.loadPage(this.pages)
      })
    },
    handleFormSubmit() {
      const isFormValid = this.$refs.editForm.validate()
      if (!isFormValid) return
      if (this.commitFlag) return
      const saveData = {
        id: this.editedItem.id,
        nickName: this.editedItem.nickName,
        phone: this.editedItem.phone,
        roleId: this.currentRoleId,
        password: this.editedItem.password,
        memberLevelId: this.currentLevelId,
        agentId: this.selectAgentId,
      }
      this.commitFlag = true

      UserApi.save(saveData).then(res => {
        this.$toast.success('保存成功')
        this.commitFlag = false
        this.closeDialog()
        this.loadPage({ page: this.pages.page, itemsPerPage: this.pages.itemsPerPage })
      })
    },
    modifyPassword() {
      const isFormValid = this.$refs.passwordForm.validate()
      if (!isFormValid) return

      UserApi.updatePassword(this.editedItem).then(res => {
        this.$toast.success('修改成功')
        this.editedItem.password = ''
        this.editedItem.repassword = ''
        this.randomPass = ''
        this.updatePasswordDialog = false
      })
    },
    lockUser(item) {
      item.accountNonLocked = !item.accountNonLocked
      UserApi.lockedUser(item).then(res => {
        if (item.accountNonLocked) {
          this.$toast.success('解锁成功')
        } else {
          this.$toast.success('锁定成功')
        }
      })
    },
    actionAllocateRole(isBind) {
      const roleIds = []
      if (isBind) {
        this.roleRltData.roleList.forEach(role => {
          if (role.hasPermission) {
            roleIds.push(role.id)
          }
        })
      }
      const saveData = {
        userId: this.roleRltData.userId,
        roleIdList: roleIds,
      }
      RoleApi.allocateUserRoles(saveData).then(res => {
        this.$toast.success('修改成功')
        this.allocateRoleDialog = false
      })
    },
    resetSearch() {
      this.$refs.searchForm.reset()
      this.toSearch()
    },
    toSearch() {
      this.loadPage({ page: 1, itemsPerPage: this.pages.itemsPerPage })
    },
    loadPage({ page, itemsPerPage }) {
      const data = {
        rows: itemsPerPage,
        page,
        name: this.search.name,
        levelId: this.search.levelId,
      }
      UserApi.getUserPage(data).then(res => {
        const { data } = res.data

        this.pages.page = data.current
        this.users = data.records
        if (data.size === -1) {
          this.pages.serverItemsLength = this.users.length
        } else {
          this.pages.serverItemsLength = data.total
        }
      })
    },
    closeDialog() {
      this.$refs.editForm.resetValidation()
      this.editedItem = this.defaultItem
      this.editDialog = false
    },
    openDeleteDialog(data) {
      this.deleteData = data
      this.deleteDialog = true
    },
    openUpdatePasswordDialog() {
      this.$nextTick(() => {
        const { passwordForm } = this.$refs
        if (passwordForm) {
          passwordForm.reset()
        }
      })
      this.updatePasswordDialog = true
    },
    openRandomPassDialog() {
      this.$nextTick(() => {
        const { randomPassForm } = this.$refs
        if (randomPassForm) {
          randomPassForm.reset()
        }
      })
      this.randomPassDialog = true
    },
    openAllocateRoleDialog(data) {
      this.roleData = []
      this.roleRltData.userId = data.id
      RoleApi.getRolesWithUserPermission(data.id).then(res => {
        const { data } = res.data
        this.roleRltData.roleList = data
        this.allocateRoleDialog = true
      })
    },
    mkdirRandomPass(length) {
      let password = ''
      for (let i = 0; i < length; i++) {
        password += this.charset.charAt(Math.floor(Math.random() * this.charset.length))
      }
      this.randomPass = password
    },
    useRandomPass() {
      this.editedItem.password = this.randomPass
      this.editedItem.repassword = this.randomPass
      this.randomPassDialog = false
    },
    initEditedItem() {
      this.defaultItem.id = ''
      this.defaultItem.password = ''
      this.defaultItem.nickName = ''
      this.defaultItem.phone = ''
      this.defaultItem.email = ''
      this.defaultItem.repassword = ''
      this.editedItem = this.defaultItem
    },
  },

}
</script>
