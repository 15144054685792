import axios from '@axios'

import qs from 'qs'

const prefixPath = '/api/system/role'
export default {

  get(id) {
    return axios.get(`${prefixPath}/get?id=${id}`)
  },

  getByUserId(userId) {
    return axios.get(`${prefixPath}/user/getByUserId`, {
      params: {
        userId,
      },
    })
  },

  save(data) {
    return axios.post(`${prefixPath}/saveOrUpdate`, data)
  },

  list() {
    return axios.get(`${prefixPath}/all`)
  },

  getRolePages(data) {
    return axios.post(`${prefixPath}/getRolePages`, data)
  },

  delete(id) {
    return axios.post(`${prefixPath}/delete`, qs.stringify({
      id,
    }))
  },

  allocateUserRoles(data) {
    return axios.post(`${prefixPath}/allocateUserRoles`, data)
  },

  getRolesWithUserPermission(userId) {
    return axios.get(`${prefixPath}/getRolesWithUserPermission`, {
      params: {
        userId,
      },
    })
  },

  toggleIsMiniApp(id, isMiniApp) {
    return axios.post(`${prefixPath}/toggleIsMiniApp`, qs.stringify({
      id,
      isMiniApp,
    }))
  },

  getWarehouseManageUserName() {
    return axios.get(`${prefixPath}/getWarehouseManageUserName`)
  },

}
